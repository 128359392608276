<template>
  <div :id="mapID" class="map-container"></div>
</template>

<script>
import {
  Convert_BD09_To_GCJ02,
  gcj02towgs84,
  out_of_china,
  wgs84togcj02,
  gcj02tobd09,
} from "../../utils/gps";
import loadScript from "../../utils/loadScript";
import { markRaw } from "vue";
import RotateIcon from "../../utils/RotateIcon";
import { fa } from "element-plus/es/locale";
import api from "../../api";
export default {
  props: {
    mapID: {
      type: String,
      default: "map-container",
    },
    ayHistory: {
      type: Array,
      default: () => [],
    },
    ayData: {
      type: Array,
      default: () => [],
    },
    ayMarker: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    ayMarker(val) {
      console.log(val, "google");
    },
    ayHistory(val) {
      this.drawHistory(val);
    },
  },
  data() {
    return {
      polyline: null,
      curr_car: 0,
      car_idx: 0,
      map_markers: new Map(),
      map_data: new Map(),
      markerClusterer: null,
      map: null,
      infowindow: null,
      infoWindow_html: "",
      ariaLabel: "",
      contentString: "",
      lnglat: { lat: -34.397, lng: 150.644 },
      ayCar: [],
      // ayHistory: [
      //   { lat: 40.7128, lng: -74.006 },
      //   { lat: 40.7129, lng: -74.0061 },
      //   { lat: 40.713, lng: -74.0062 },
      //   { lat: 40.7131, lng: -74.0063 },
      //   { lat: 40.7132, lng: -74.0064 },
      // ],
      markerHistory: null,
      indexHistory: 0,
      timerHistory: null,
      animation: null,
      drawingManager: null,
      timer_mc: null,
      timer: null,
      ay_google: [],
      ay_clear: [],
      bounds: null,
      carMarker: null,
      isRun: false,
    };
  },
  mounted() {
    window.initMap = this.initMap;
    this.loadScripts();
  },
  methods: {
    clearAll() {
      //清理地图上图标
      this.ay_clear.forEach((it) => {
        it.setMap(null);
      });
    },
    setCurrCar(id) {
      this.curr_car = id;
    },
    posCar(row) {
      let lnglat = this.getWgs84ToGcj02(row.lng, row.lat);
      let pt = new google.maps.LatLng(lnglat);
      pt.time = row.time;
      //this.pt.time = "<div class='aa'>" + pt.time + "</div>";
      pt.cph = row.cph;
      pt.ang = row.ang;
      this.setCar(pt);
    },
    nextCar() {
      this.isRun = true;
      this.clickFun("play");
    },
    pauseCar() {
      this.isRun = false;
      this.clickFun("pause");
    },
    stopCar() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.setCar(this.ay_google[0]);
      this.clickFun("stop");
    },
    playCar() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.car_idx = 0;
      this.isRun = true;
      // this.isAni = true;
      this.timer = setInterval(() => {
        if (this.isRun) {
          if (this.car_idx < this.ay_google.length) {
            this.setCar(this.ay_google[this.car_idx++]);
          } else {
            clearInterval(this.timer);
            // this.isAni = false;
            this.car_idx = 0;
            this.setCar(this.ay_google[this.car_idx++]);
            this.clickFun("stop");
          }
        }
      }, 100);
      this.clickFun("play");
    },
    drawHistory(ay) {
      //清理地图上图标
      this.ay_clear.forEach((it) => {
        if (it) {
          it.setMap(null);
        }
      });
      // console.log("draw", ay);
      // return;
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (this.carMarker) {
        this.carMarker.setMap(null);
      }
      this.carMarker = null;
      if (ay.length == 0) return;

      // if(this.ay_marker.length>0){
      //   this.map.clear
      // }
      this.bounds = new google.maps.LatLngBounds();
      //转换百度经纬度
      // let ay_google = [];
      this.ay_google.splice(0);
      let lnglat;
      let pt;
      ay.forEach((it) => {
        lnglat = this.getWgs84ToGcj02(it.lng, it.lat);
        pt = new google.maps.LatLng(lnglat);
        pt.time = it.time;
        pt.cph = it.cph;
        pt.ang = it.ang;
        this.ay_google.push(pt);
        this.bounds.extend(pt);
      });
      //画路线
      //this.drawRoad();
      if (this.polyline == null) {
        this.polyline = new google.maps.Polyline({
          path: this.ay_google,
          geodesic: true,
          strokeColor: "#0dbc79",
          strokeOpacity: 1.0,
          strokeWeight: 4,
        });

        this.polyline.setMap(this.map);
      } else {
        this.polyline.setPath(this.ay_google);
      }

      this.map.panTo(this.ay_google[0]);
      this.map.fitBounds(this.bounds);

      this.createMarker(
        this.ay_google[0],
        require("../../assets/map/1.png"),
        null
      );
      this.createMarker(
        this.ay_google[this.ay_google.length - 1],
        require("../../assets/map/2.png"),
        null
      );
      this.setCar(this.ay_google[0]);
    },
    drawHistory_old(ay) {
      //清理地图上图标
      this.ay_clear.forEach((it) => {
        if (it) {
          it.setMap(null);
          it = null;
        }
      });
      console.log("draw", ay);
      // return;
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (this.carMarker) {
        this.carMarker.setMap(null);
      }
      this.carMarker = null;
      if (ay.length == 0) return;

      // if(this.ay_marker.length>0){
      //   this.map.clear
      // }
      this.bounds = new google.maps.LatLngBounds();
      //转换百度经纬度
      // let ay_google = [];
      this.ay_google.splice(0);
      let lnglat;
      let pt;
      ay.forEach((it) => {
        lnglat = this.getWgs84ToGcj02(it.lng, it.lat);
        pt = new google.maps.LatLng(lnglat);
        pt.time = it.time;
        pt.cph = it.cph;
        pt.ang = it.ang;
        this.ay_google.push(pt);
        this.bounds.extend(pt);
      });
      //画路线
      this.drawRoad();
    },
    drawRoad() {
      const polyline = new google.maps.Polyline({
        path: this.ay_google,
        geodesic: true,
        strokeColor: "#0dbc79",
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });

      polyline.setMap(this.map);
      this.ay_clear.push(polyline);
      this.map.panTo(this.ay_google[0]);
      this.map.fitBounds(this.bounds);

      this.createMarker(
        this.ay_google[0],
        require("../../assets/map/1.png"),
        null
      );
      this.createMarker(
        this.ay_google[this.ay_google.length - 1],
        require("../../assets/map/2.png"),
        null
      );
      this.setCar(this.ay_google[0]);
    },
    setCar(pt) {
      if (this.carMarker == null) {
        this.carMarker = new google.maps.Marker({
          position: pt,
          icon: {
            url: RotateIcon.makeIcon("images/1.png")
              .setRotation({ deg: pt.ang })
              .getUrl(),
            // This marker is 20 pixels wide by 32 pixels high.
            // size: new google.maps.Size(32, 32),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(16, 16),
          },
          // icon: RotateIcon.makeIcon("images/1.png")
          //   .setRotation({ deg: pt.ang })
          //   .getUrl(),
          label: {
            text: pt.time,
            className: "gpsMap",
            color: "#f00",
          },
        });

        this.carMarker.setMap(this.map);

        // this.carMarker.setRotation(pt.ang);

        // marker.addEventListener("click",  (evt)=> {
        //   this.infoWindow_html = this.baiPop_html(evt.currentTarget.row);
        //   this.infoWindow.setContent(this.infoWindow_html);
        //   evt.currentTarget.openInfoWindow(this.infoWindow);
        // });
      } else {
        this.carMarker.setPosition(pt);
        // this.carMarker.setRotation(pt.ang);
        // this.carMarker.getLabel().setPosition(pt);
        let imgUrl = RotateIcon.makeIcon("images/1.png")
          .setRotation({ deg: pt.ang })
          .getUrl();
        console.log("history", imgUrl);
        if (imgUrl != "" && imgUrl != null) {
          this.carMarker.setIcon({
            url: imgUrl,
            // This marker is 20 pixels wide by 32 pixels high.
            // size: new google.maps.Size(32, 32),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(16, 16),
          });
        }
        this.carMarker.setLabel({
          text: pt.time,
          className: "gpsMap",
          color: "#f00",
        });
        // this.carMarker.setIcon({
        //   url: RotateIcon.makeIcon("images/1.png")
        //     .setRotation({ deg: pt.ang })
        //     .getUrl(),
        //   // This marker is 20 pixels wide by 32 pixels high.
        //   // size: new google.maps.Size(32, 32),
        //   // The origin for this image is (0, 0).
        //   origin: new google.maps.Point(0, 0),
        //   // The anchor for this image is the base of the flagpole at (0, 32).
        //   anchor: new google.maps.Point(16, 16),
        // });
        // this.carMarker.setIcon(
        //   RotateIcon.makeIcon("images/1.png")
        //     .setRotation({ deg: pt.ang })
        //     .getUrl()
        // );
      }
      this.map.panTo(pt);
    },
    createMarker(pt, imgPath, row) {
      let marker = new google.maps.Marker({
        position: pt,
        icon: imgPath,
      });
      marker.addListener("click", (evt) => {
        if (row) {
          this.showInfoWindow(evt.latLng, row.html);
        }
      });
      marker.setMap(this.map);
      this.ay_clear.push(marker);
    },
    posMarker(row) {
      this.addMarker(row);
    },
    removeMaerker(row) {
      let mapKey = "map" + row.id;
      if (this.map_markers.has(mapKey)) {
        let marker = this.map_markers.get(mapKey);
        this.infowindow.close();
        // marker.setMap(null);
        // marker.setVisible(false);
        console.log("removeMaerker", marker);
        // console.log("remove", this.markerClusterer);
        this.markerClusterer.removeMarker(marker, true);
        // this.markerClusterer.reset();
        // console.log("markers", this.markerClusterer.getMarkers());
        // this.markerClusterer.clearMarkers(true);
        this.map_markers.delete(mapKey);
      }
    },
    addMarkers(ay, is_pos = 1) {
      if (ay.length > 0) {
        let pt;
        let marker;
        let mapKey;
        let ayTemp = [];
        ay.forEach((row) => {
          pt = new google.maps.LatLng(this.getWgs84ToGcj02(row.lng, row.lat));
          mapKey = "map" + row.id;
          marker;
          if (this.map_markers.has(mapKey)) {
            //更新位置
            marker = this.map_markers.get(mapKey);
            marker.setPosition(pt);
          } else {
            //添加marker
            marker = new google.maps.Marker({
              position: pt,
              icon: row.icon,
            });
            marker.addListener("click", (evt) => {
              this.showInfoWindow(evt.latLng, row.html);
            });
            this.map_markers.set(mapKey, marker);
          }
          ayTemp.push(marker);
        });
        this.markerClusterer.addMarkers(ayTemp, true);
        //this.markerClusterer.clearMarkers()
        //this.markerClusterer.removeMarker(marker,true)
        //this.markerClusterer.removeMarkers(ayTemp,true)
        setTimeout(() => {
          if (is_pos == 1) {
            this.map.panTo(pt);
          }
        }, 500);
      }
    },
    addMarker(row, is_pos = 1) {
      console.log("addMarker-addMarker is_pos", is_pos, row);
      let pt = new google.maps.LatLng(this.getWgs84ToGcj02(row.lng, row.lat));
      let mapKey = "map" + row.id;
      let marker;
      if (this.map_markers.has(mapKey)) {
        //更新位置
        marker = this.map_markers.get(mapKey);
        marker.setPosition(pt);
        // marker.setIcon(row.icon);
        marker.setIcon({
          url: row.icon,
          // This marker is 20 pixels wide by 32 pixels high.
          size: new google.maps.Size(36, 36),
          // The origin for this image is (0, 0).
          origin: new google.maps.Point(0, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
          anchor: new google.maps.Point(18, 18),
        });
      } else {
        //添加marker
        marker = new google.maps.Marker({
          position: pt,
          //设置偏移量
          // anchorPoint: new google.maps.Point(-10, -10),
          icon: {
            url: row.icon,
            // This marker is 20 pixels wide by 32 pixels high.
            size: new google.maps.Size(36, 36),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(18, 18),
          },
        });
        marker.addListener("click", (evt) => {
          this.showInfoWindow(evt.latLng, row.html);
        });
        this.map_markers.set(mapKey, marker);
        this.markerClusterer.addMarker(marker, true);
        // marker.setMap(this.map);
      }
      this.showInfoWindow(pt, row.html);
      if (is_pos == 1) {
        this.map.panTo(pt);
      }

      setTimeout(() => {
        // this.map.setZoom(18);
      }, 100);
    },
    setMarkers(map) {
      this.map_data = map;
      this.drawMarkers();
    },
    drawMarkers() {
      let mapKey;
      let marker;
      let pt;
      for (let [key, row] of this.map_data) {
        // console.log("google row", row);
        pt = new google.maps.LatLng(this.getWgs84ToGcj02(row.lng, row.lat));
        mapKey = "map" + row.id;
        if (this.map_markers.has(mapKey)) {
          //更新位置
          marker = this.map_markers.get(mapKey);
          marker.setPosition(pt);
        } else {
          //添加marker
          marker = new google.maps.Marker({
            position: pt,
            icon: row.icon,
            map: this.map,
          });
          marker.addListener("click", (evt) => {
            this.showInfoWindow(evt.latLng, row.html);
          });
          this.map_markers.set(mapKey, marker);
          this.markerClusterer.addMarker(marker, true);
        }
      }
      this.map.panTo(pt);
    },
    getWgs84ToGcj02(lng, lat) {
      if (!out_of_china(lng, lat)) {
        let ay = wgs84togcj02(lng, lat);
        // console.log("wgs84togcj02", ay);

        return { lng: ay[0], lat: ay[1] };
      } else {
        console.log("out_of_china");
        return { lng: parseFloat(lng), lat: parseFloat(lat) };
      }
    },
    async loadScripts() {
      const res = await api.sys_get_xtpz({});
      // console.log("wxw", res.data.row.googleKey);
      // let ele = document.getElementById("googleScript");
      // if (!ele) {
      //   var script = document.createElement("script");
      //   script.type = "text/javascript";
      //   script.id = "googleScript";
      //   script.src =
      //     "https://maps.googleapis.com/maps/api/js?key=AIzaSyDUXiSEA0nXGCQhWjoWOfESxb5CmRiaVu0&libraries=drawing&callback=initMap";
      //   document.body.appendChild(script);
      //   var script2 = document.createElement("script");
      //   script2.type = "text/javascript";
      //   // script2.id = "googleScript";
      //   script2.src = "./map/GoogleMarkerClusterer.js";
      //   document.body.appendChild(script2);
      // } else {
      //   this.initMap();
      // }
      let ele = document.getElementById("googleMapEle");
      if (!ele) {
        loadScript(
          "googleMapEle",
          "https://maps.googleapis.com/maps/api/js?key=" +
            res.data.row.googleKey +
            "&libraries=drawing&callback=initMap"
        );
      } else {
        this.initMap();
      }
    },
    async initMap() {
      let ele = document.getElementById("googleMarkerClusterer");
      if (!ele) {
        await loadScript(
          "googleMarkerClusterer",
          "./map/GoogleMarkerClusterer.js"
        );
      }
      this.map = markRaw(
        new google.maps.Map(document.getElementById(this.mapID), {
          center: this.lnglat,
          zoom: 13,
          minZoom: 4,
          maxZoom: 18,
        })
      );
      //根据浏览定位
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(
      //     (position) => {
      //       this.lnglat = {
      //         lat: position.coords.latitude,
      //         lng: position.coords.longitude,
      //       };
      //       this.map.panTo(this.lnglat);
      //       // this.map.setCenter(this.lnglat);
      //     },
      //     () => {
      //       console.log("location error");
      //       // handleLocationError(true, infoWindow, map.getCenter());
      //     }
      //   );
      // }
      //消息框
      this.infowindow = new google.maps.InfoWindow({
        pixelOffset: new google.maps.Size(0, -15),
      });

      // if (this.timer_mc) clearInterval(this.timer_mc);
      // this.timer_mc = setInterval(() => {
      //   if (window.markerClusterer.MarkerClusterer) {
      //     this.markerClusterer = new window.markerClusterer.MarkerClusterer({
      //       map: this.map,
      //     });
      //     console.log("markerClusterer", this.markerClusterer);
      //     clearInterval(this.timer_mc);
      //   }
      // }, 1000);
      // console.log("markerClusterer1", this.markerClusterer);

      this.markerClusterer = new window.markerClusterer.MarkerClusterer({
        map: this.map,
      });

      //显示消息
      // this.showInfoWindow(this.lnglat, "wxw<br/>zjyhcn");
      // 创建一个表示车辆位置的标记
      this.markerHistory = new google.maps.Marker({
        position: this.ayHistory[0], // 设置初始位置
        map: this.map,
      });

      // 创建动画效果
      // this.animation = google.maps.Animation.BOUNCE;
      //测试动画
      // this.playHistory();
      // this.initDraw();

      //加载地图数据
      this.initData();
    },
    initData() {
      console.log("google", this.ayMarker);
      //加载位置
      // let pt = this.bd09towgs84(this.ayMarker[0].lng, this.ayMarker[0].lat);
      // this.lnglat = new google.maps.LatLng(pt);
      // new google.maps.Marker({
      //   position: this.lnglat,
      //   map: this.map,
      //   title: "Hello World!",
      // });
      // this.map.panTo(this.lnglat);
      //初始化海量点
      this.addMarkers(this.ayData);

      this.drawHistory(this.ayHistory);
    },
    drawModel(drawType) {
      switch (drawType) {
        case 1:
          this.drawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.MARKER
          );
          break;
        case 2:
          this.drawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.CIRCLE
          );
          break;
        case 3:
          this.drawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.POLYGON
          );
          break;
        case 4:
          this.drawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.POLYLINE
          );
          break;
        case 5:
          this.drawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.RECTANGLE
          );
          break;
        case 6:
          this.drawingManager.setDrawingMode(null);
          break;
      }
    },
    initDraw() {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.MARKER,
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.POLYLINE,
            google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
        markerOptions: {
          icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        },
        circleOptions: {
          fillColor: "#ffff00",
          fillOpacity: 1,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      });

      google.maps.event.addListener(
        this.drawingManager,
        "overlaycomplete",
        (event) => {
          console.log(event);
          if (event.type == google.maps.drawing.OverlayType.CIRCLE) {
            var radius = event.overlay.getRadius();
          }
        }
      );
      this.drawingManager.setMap(this.map);
    },
    playHistory() {
      if (this.timerHistory) clearInterval(this.timerHistory);
      this.map.panTo(this.ayHistory[0]);
      let bounds = new google.maps.LatLngBounds();
      this.ayHistory.forEach((it) => {
        bounds.extend(it);
      });

      this.map.panToBounds(bounds);
      this.timerHistory = setInterval(() => {
        this.updateMarkerHistory();
        this.indexHistory = (this.indexHistory + 1) % this.ayHistory.length;
      }, 1000);
    },
    // 更新车辆位置的函数
    updateMarkerHistory() {
      this.markerHistory.setPosition(this.ayHistory[this.indexHistory]); // 更新位置
      // this.markerHistory.setAnimation(this.animation); // 设置动画效果
    },
    showInfoWindow(lanlag, html) {
      this.infowindow.setContent(html);
      this.infowindow.setPosition(lanlag);
      this.infowindow.open(this.map);
    },
    bd09towgs84(lng, lat) {
      let pt = Convert_BD09_To_GCJ02(lng, lat);
      if (out_of_china(lng, lat)) {
        let ay = gcj02towgs84(pt.lng, pt.lat);
        return { lng: ay[0], lat: ay[1] };
      } else {
        return pt;
      }
    },
    clickFun(flag, obj = {}) {
      this.$emit("MAPEVENT", Object.assign({ type: flag }, obj));
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 100%;
}
</style>
<style>
.gpsMap {
  margin-top: -25px;
  font-weight: 700;
}
.gmnoprint {
  display: none !important;
}
a[rel="noopener"] {
  display: none !important;
}
</style>

export default {
  chinese: "Chinese",
  banbenhao: "Version",
  rRectType: "Type",
  rRectName: "Name",
  rRectPoints: "Coordinate",
  rCompanyID: "Company",
  rMore: "Remark",
  rSortCode: "Sequence",
  rImg: "Icon",
  group_name: "Fleet",
  guzhang: "Health",
  xiazai: "Download",
  xiugaimima: "Change Password",
  tuichu: "Exit",
  caozuo: "Request",
  zhimingjingbao: "FATAL ALERT",
  pilaobaojing: "Fatigue",
  baojingshijian: "WARNING",
  jinggaozhuyi: "ALARM",
  yichangduandian: "Power Alarm",
  tuoche: "Towed",
  shipinzhendang: "Camcover",
  shijiantongzhi: "NOTICE",
  acckaiguanbaobiao: "Ignition Data",
  xiangqingtongji: "Details",
  jintian: "Today",
  zuotian: "Yesterday",
  benzhou: "This Week",
  shangzhou: "Last Week",
  benyue: "This Month",
  shangyue: "Last Month",
  xiayue: "Next Month",
  zidingyi: "Customize",
  changguibaojing: "Alarm Data",
  dianziweilan: "Fence",
  guzhangbaojing: "Health",
  cheliangjiashipingfen: "Vehicle Rating",
  lichengtongji: "Trip Data",
  shujuliuliang: "Cellular Data",
  shangxiaxianbaobiao: "Online Data",
  yonghurizhi: "User Log",
  fangwenduan: "Type",
  zhudonganquanbaojing: "AI",
  weifenpei: "Not Authorized",
  yifenpei: "Authorized",
  simka: "SIM Card",
  shiyongzhuangtai: "State",
  cheliangfenpei: "Vehicle",
  chezufenpei: "Fleet",
  qingxuanzechezu: "Please select fleet​.",
  tianjiachezu: "Add",
  xiugaichezu: "Edit",
  shanchuchezu: "Delete",
  mingcheng: "Name",
  chezuguanli: "Fleet",
  shanchu: "Delete",
  duobianxing: "Polygon",
  yuanxing: "Round",
  juxing: "Rectangle",
  xianlu: "Route",
  xingqudian: "Location",
  qingxuanzeshanchuxiang: "Please select deleting clause.",
  mingchengbitian: "Please fill in name.​",
  tianjia: "Add",
  gengxinchenggong: "Update Done",
  pilaoguize: "Fatigue",
  jinruguize: "​No Entry",
  jinchuguize: "No Exit​",
  quyuxiansu: "Fence Spd​",
  yejianxingche: "Night Trip​",
  dingshipaizhao: "Time Snap​",
  qingxuanzegongsi: "Please select company.",
  shurudemimabuyizhi: "Password entered not the same",
  shipinhuifang: "Timeline",
  wenjianxiazai: "Download",
  zhongduanshebei: "Device",
  qingchaxun: "Search",
  zhaopianchakan: "Image",
  dangqiandi: "Current Page",
  zhanggong: "Pcs/Total",
  zhang: "Pcs",
  chepai: "Plate No.​",
  tupian: "Image",
  cheliangshebei: "Device",
  cheliangpingfen: "Vehicle Rating",

  baojingtongji: "Alarm Data",
  cipanbaojing: "Disk",
  shipinbaojing: "Video",
  sosbaojing: "Panic",
  shipinshebeizongshu: "Total Video Device",
  zhuizongqizongshu: "Total Trackers",
  baiduditu: "Baidu Map",
  gugeditu: "Google Map",
  guzhangxinxi: "Health Info",
  gpsbudingwei: "GPS Error",
  cipanzhuangtai: "Disk Status",
  ditushipinxiangqing: "Map/Video",
  dituguiji: "Trip",
  baojijngxiangqing: "Alarm Details",
  baojingfashengdian: "Location",
  baojingqian10fenzhong: "10 Mins Pre Alarm",
  baoingqian30fenzhong: "30 Mins Before Alarm",
  baojingqian60fenzhong: "60 Mins Before Alarm",
  suduquxian: "Speed Curve",
  luxianghuifang: "Playback",
  shishibofang: "Liveview",
  baojingtupian: "Event Image",
  baojingshipin: "Event Video",
  baojingchuli: "Handle",
  huanchongmiao: "Buffering(Sec)",
  shuru: "Input",
  xiaohui: "Destroy",
  quxiaojingyin: "Disable Mute",
  jingyin: "Mute",
  yinliang: "Volume",
  xuanzhuang: "Rotate",
  quanping: "Fullscreen",
  jietu: "Snapshot",
  caozuoanniu: "Request",
  wangsu: "Network",
  //xingneng: "Performance",
  //lipingxuanran: "Rendering",
  //wanquantianchonglashen: "Full-fit(stretched)",
  //dengbisuofang: "Uniform scale",
  //wanquantianchongweilashen: "Full-fit(non stretched)",
  qingping: "Clear",
  luzhi: "Record",
  //zantingluzhi: "Pause",
  //fengkuangjiazaizhong: "Loading hard",
  //kadun: "Lagging",
  //feichangliuchang: "Smooth",
  yi: "Mon",
  er: "Tue",
  san: "Wed",
  si: "Thu",
  wu: "Fri",
  liu: "Sat",
  ri: "Sun",
  liuchang: "Smooth",
  xuanze: "Select",
  zanwufuhedexiazaiwenjian: "No Data.",
  xiangqing: "Details",
  daochu: "Export",
  tongji: "Trip",
  baobiao: "Report",
  jixu: "Continue",
  zanting: "Pause",
  jieshu: "End",
  dianwokaishi: "Click To Start",
  huizhiwancheng: "Drawing Done",
  bianjiwancheng: "Editting Done",
  shanchuyibaocundequyu: "Delete The Saved Zones",
  dianjiditukaishihuizhiduobianxing: "Clip Map To Draw Polygon",
  jixuxuanze: "Continue Selecting",
  dianjidiyigedingdianwanchenghuizhi: "Click The First Peak To Finish",
  huizhi: "Drawing",
  qingchuquanbu: "Delete All",
  zanwuquyukebianji: "No Editable Zones",
  xuanzhongyigequyuquqingchu: "Select Zone To Clear",
  bianji: "Edit",
  shanchu: "Delete",
  quxiaohuizhi: "Cancel Drawing",
  wancheng: "Done",
  wanchenghuizhi: "Drawing Done",
  chexiao: "Backout",
  dianjiquxiaoyichenxiaogenggai: "Click cancael to backout changing",
  tuodongbiaojiyibianjituxing: "Drag icon to edit graph",
  huizhiwanchengshidiaoyong: "Invocation after drawed",
  jinzhihuizhihuizhijieshu: "No drawing、end drawing",
  xiazaijindu: "Download Progress",

  // zhxx: "Vehicle Info",
  // yt: "PTZ",
  cph_name: "Plate No.",
  // sbid_name: "Device ID",
  ssgs_name: "Company",
  sbid: "ID",
  ssgs: "Company",

  // sscd_name: "Fleet",
  // zxzt_name: "Online Status",
  // zhzxsj_name: "Online Time",

  // bjhz_name: "Alarm Total",
  zxbjsj_name: "Time",
  yuanyin: "Cause",
  tongdao: "Channel",
  qiye: "Company",
  zxbjnr_name: "Content",
  bjwz_name: "Location",
  sjxm_name: "Driver Name",
  sjdh_name: "Driver Phone",
  // whryxm_name: "Maintence Name",
  // whrxdh_name: "Maintence Phone",
  // bxlx_name: "Assurance Type",
  // bxdh_name: "Assurance Phone",

  zt_1: "Online",
  zt_0: "Offline",
  zt_2: "Alarm",
  zt_3: "Online",

  item_ylsysp: "Preview All Video",
  item_ksdj: "Intercom",
  item_ksjt: "Listen",
  item_xfwzxx: "Send TXT",
  item_gpssbsj: "GPS Report Time",
  item_clkz: "Vehicle control",
  item_gpssbjgsz: "GPS Report Time",
  item_wlllpz: "Data Flow Config",
  item_wifixzrwsz: "WIFI Download",
  item_cp: "Reboot",
  item_gshcp1: "​Format HDD",
  item_gshcp2: "Format SD",
  item_jzg: "Adjust GSENSOR",
  item_sbxx: "Device Info​",
  item_sbsj: "Device Upgrading​",
  item_xfwj: "Send File",
  item_wifipzzd: "WIFI Diagnose",
  item_lxsj: "Offline Upgrading",
  item_sbcssz: "Parameter Set​",

  pop_sj: "Time",
  pop_sd: "Speed",
  pop_wz: "Location",
  pop_lc: "Trip",
  pop_zt: "State",
  pop_bj: "Alarm",
  pop_sp: "Video",
  pop_dj: "Intercom",
  pop_jt: "Listen",
  pop_zp: "Snapshot",
  pop_gd: "More",
  pop_dkzzms: "Enable Tracking Mode",
  pop_gbzzms: "Disable Tracking Mode",
  pop_cssz: "Parameter Set",
  pop_wqwz: "Location",

  // send_success: "Save Done",
  // send_fail: "Save Failure",
  gps_0: "(LBS)",
  gps_1: "(GPS)",
  gps_2: "(No GPS)",

  // search_veh: "Device ID",
  // btn_search: "Search",
  // btn_xssp: "Display Video",
  // btn_ycsp: "Hide Video",
  // tree_root_name: "All Data",

  // MaxCarError: "Exceeded the maximum display quantity.",
  maps: "Map",
  satellite: "Satellite Map​",

  blx: "No Record",
  yz: "Turn Right",
  zz: "Turn Left",
  cc: "Brake​",
  dc: "Reverse",
  yxh: "No Signal",
  xhc: "Signal Weak",
  xhyb: "Signal Not Bad",
  xhh: "Signal Good",
  wx: "GPS",
  yx: "Wired",
  wz: "Unknown",

  // cz: "Request",
  // yes: "Save",
  no: "Return",
  // video_content: "Video streaming reach the time threshold of auto-off, postpone?",
  bjjb: "Alarm level",
  ckmx: "Details",
  // yjbj: "Level 1 Alarm",
  // ejbj: "Level 2 Alarm",

  // zdaqbj: "AI",

  // one: "Single Window",
  // four: "Quad Window",
  // six: "Six Window",
  item_info: "Device Info",
  item_intercom: "Start Intercom",
  item_gshcp: "Format",
  item_lcsz: "Mileage",
  item_drive: "Driving setup",
  // top_CompanyID: "Company",
  // top_bindid: "Fleet",
  // msgType_4: "No Permission.",
  btnSave: "Save",

  // pernumber: "",
  // pagestat: "",
  // pagefrom: "",
  // pagetext: "",
  // pagetotal: "/",
  // findtext: "Search",
  // procmsg: "Handling，Wait...",
  // nomsg: "No Data.",

  // panel_title: "Basic Info",
  // rRectName: "Name",
  // rRectPoints: "Coordinate",
  // rCompanyID: "Company",
  // rMore: "Remark",
  // rSortCode: "Sequence",
  // rImg: "Icon",
  // top_CompanyID: "Please Select Company.",
  // top_bindid: "Please Select Fleet.",
  // xzcl: "Select Vehicle",

  track_cz: "Search",
  track_kssj: "Start Time",
  track_jssj: "End Time",
  track_jj: "Distance",
  track_lc: "Trip",
  track_zchf: "Normal Playback",
  track_kshf: "Fast Playback",
  track_xsgjd: "Display Tracks",
  track_cx: "Search",
  bangding: "Authorization",
  jiebang: "Revoke Authorization",
  track_bf: "Play",
  tingzhi: "Stop",

  track_gjd: "Track",

  track_cph: "Plate No.",
  track_sj: "Time",
  track_sd: "Speed",
  track_dslc: "Trip",
  track_wz: "Location",
  track_zt: "State",
  track_bj: "Alarm",

  a_1: "Panic",
  a_2: "Overspeed",
  a_3: "Fatigue",
  a_4: "Early Warning",
  a_5: "GPS Error",
  a_6: "GPS Cable Error",
  a_7: "GPS Cable Shorted",
  a_8: "Main Voltage Low",
  a_9: "No Power",
  a_10: "LCD Error",

  a_11: "TTS Error",
  a_12: "Camera Error",
  a_13: "IC Card Error",
  a_14: "Overspd Early Warning",
  a_15: "Fatigue Early Warning",
  a_16: "",
  a_17: "",
  a_18: "",
  a_19: "Duration/Day",
  a_20: "Idiling",

  a_21: "Fence",
  a_22: "Route",
  a_23: "Route Time",
  a_24: "Route Deviation",
  a_25: "VSS Error",
  a_26: "Fuel Alarm",
  a_27: "Car Stolen",
  a_28: "Illegal Ignition",
  a_29: "Illegal Trip",
  a_30: "Collision",

  a_31: "Rollover",
  a_32: "Illegal Entry",
  a_33: "Fence Overspd(CNMS)",
  a_34: "Fence Lowspd(CNMS)",
  a_35: "Fence(CNMS)",
  a_36: "Route Deviation(CNMS)",
  a_37: "Time Slot Overspeed(CNMS)",
  a_38: "Time Slot Lowspd(CNMS)",
  a_39: "Fatigue(CNMS)",
  a_40: "Idiling(CNMS)",

  a_41: "Key Location Alarm(CNMS)",
  a_42: "Route Overspeed(CNMS)",
  a_43: "Route Lowspd(CNMS)",
  a_44: "Road Overspd(CNMS)",
  a_45: "Night Trip(CNMS)",
  a_46: "",
  a_47: "",
  a_48: "",
  a_49: "",
  a_50: "",

  a_51: "",
  a_52: "",
  a_53: "",
  a_54: "",
  a_55: "",
  a_56: "",
  a_57: "",
  a_58: "",
  a_59: "",
  a_60: "",

  a_61: "",
  a_62: "",
  a_63: "",
  a_64: "",

  s_1: { s0: "Ignition OFF", s1: "Ignition ON" },
  s_2: { s0: "NO GPS", s1: "GPS" },
  s_3: { s0: "North Latitude", s1: "South Latitude" },
  s_4: { s0: "East Longitude", s1: "West Longitud" },
  s_5: { s0: "In Operation", s1: "​Out Operation" },
  s_6: {
    s0: "Longitude latitude not encripted",
    s1: "Longitude latitude encripted",
  },
  s_7: { s0: "", s1: "" },
  s_8: { s0: "", s1: "" },
  s_9: { s00: "Empty", s01: "Half Loaded", s01: "", s11: "Full Loaded​" },
  // 's_10':{'s0':'','s1':''},

  s_11: { s0: "Fuel ON", s1: "Fuel OFF" },
  s_12: { s0: "Power ON", s1: "Power OFF" },
  s_13: { s0: "Door ON", s1: "Door OFF" },
  s_14: { s0: "Door 1 OFF", s1: "Door 1 ON（Front）" },
  s_15: { s0: "Door 2 OFF", s1: "Door 2 ON（Middle）" },
  s_16: { s0: "Door 3 OFF", s1: "Door 3 ON（Rear）" },
  s_17: { s0: "Door 4 OFF", s1: "Door 4 ON（Driver）" },
  s_18: { s0: "Door 5 OFF", s1: "Door 5 ON（Customize）" },
  s_19: { s0: "GPS Not Use", s1: "GPS Use" },
  s_20: { s0: "BD Not Use", s1: "BD Use" },

  s_21: { s0: "Glonass Not Use", s1: "Glonass Use" },
  s_22: { s0: "Galileo Not Use", s1: "Galileo Use" },
  s_23: { s0: "", s1: "" },
  s_24: { s0: "", s1: "" },
  s_25: { s0: "", s1: "" },
  s_26: { s0: "", s1: "" },
  s_27: { s0: "", s1: "" },
  s_28: { s0: "", s1: "" },
  s_29: { s0: "", s1: "" },
  s_30: { s0: "", s1: "" },
  s_31: { s0: "", s1: "" },
  s_32: { s0: "", s1: "" },

  s_33: { s0: "Low Beam OFF", s1: "Low Beam ON" },
  s_34: { s0: "High Beam OFF", s1: "High Beam ON" },
  s_35: { s0: "Right-Turn OFF", s1: "Right-Turn ON" },
  s_36: { s0: "Left-Turn OFF", s1: "Left-Turn ON" },
  s_37: { s0: "Brake OFF", s1: "Brake ON" },
  s_38: { s0: "Reverse OFF", s1: "Reverse ON" },
  s_39: { s0: "Fog Light OFF", s1: "Fog Light ON" },
  s_40: { s0: "Contour Light OFF", s1: "Contour Light ON" },

  s_41: { s0: "Buzzer OFF", s1: "Buzzer ON" },
  s_42: { s0: "Air Conditioner OFF", s1: "Air Conditioner ON" },
  s_43: { s0: "Neutral Gear OFF", s1: "Neutral Gear ON" },
  s_44: { s0: "Retarder OFF", s1: "Retarder ON" },
  s_45: { s0: "ABS OFF", s1: "ABS ON" },
  s_46: { s0: "Heater OFF", s1: "Heater ON" },
  s_47: { s0: "Clutch OFF", s1: "Clutch ON" },
  s_48: { s0: "", s1: "" },
  s_49: { s0: "", s1: "" },
  s_50: { s0: "", s1: "" },

  s_51: { s0: "", s1: "" },
  s_52: { s0: "", s1: "" },
  s_53: { s0: "", s1: "" },
  s_54: { s0: "", s1: "" },
  s_55: { s0: "", s1: "" },
  s_56: { s0: "", s1: "" },
  s_57: { s0: "", s1: "" },
  s_58: { s0: "", s1: "" },
  s_59: { s0: "", s1: "" },
  s_60: { s0: "", s1: "" },

  s_61: { s0: "", s1: "" },
  s_62: { s0: "", s1: "" },
  s_63: { s0: "", s1: "" },
  s_64: { s0: "", s1: "" },

  e_1: "Panic",
  e_2: "Overspeed",
  e_3: "Fatigue",
  e_4: "Early Warning",
  e_5: "GPS Error",
  e_6: "GPS Cable Error",
  e_7: "GPS Cable Shorted",
  e_8: "Main Voltage Low",
  e_9: "No Power",
  e_10: "LCD Error",
  e_11: "TTS Error",
  e_12: "Camera Error",
  e_13: "IC Card Error",
  e_14: "Overspd Early Warning",
  e_15: "Fatigue Early Warning",
  e_19: "Duration/Day",
  e_20: "Idiling",
  e_21: "Fence",
  e_22: "Route",
  e_23: "Route Time",
  e_24: "Route Deviation",
  e_25: "VSS Error",
  e_26: "Fuel Alarm",
  e_27: "Car Stolen",
  e_28: "Illegal Ignition",
  e_29: "Illegal Trip",
  e_30: "Collision",
  e_31: "Rollover",
  e_32: "Illegal Entry",

  e_33: "Camera Error",
  e_34: "Cam-Covered",
  e_35: "Disk Alarm",
  e_36: "Other Video Error",
  e_37: "Overload Alarm",
  e_38: "Abnormal Driving",
  e_39: "Event video memory reach the threshold",

  e_65: "Fence Overspd",
  e_66: "Fence Lowspd",
  e_67: "Fence",
  e_68: "Route Deviation",
  e_69: "Time Slot Overspeed",
  e_70: "Time Slot Lowspd",
  e_71: "Fatigue",
  e_72: "Idiling",
  e_73: "Key Location Alarm",
  e_74: "Route Overspeed",
  e_75: "Route Lowspd",
  e_76: "Road Overspd",
  e_77: "Night Trip",
  e_78: "In Fence",
  e_79: "Out Fence",

  e_101: "Camera Error",
  e_102: "Cam-Covered",
  e_103: "Disk Alarm",
  e_104: "Other Video Error",
  e_105: "Overload Alarm",
  e_106: "Abnormal Driving Alarm",
  e_107: "Event video memory reach the threshold",

  e_201: "Fence Overspd",
  e_202: "Fence Lowspd",
  e_203: "Fence",
  e_204: "Route Deviation",
  e_205: "Time Slot Overspeed",

  e_206: "Time Slot Lowspd",
  e_207: "Fatigue",
  e_208: "Idiling",
  e_209: "Key Location Alarm",
  e_210: "Route Overspeed",
  e_211: "Route Lowspd",
  e_212: "Road Overspd",
  e_213: "Night Trip",
  e_214: "In Fence",
  e_215: "Out Fence",
  e_216: "Remove",
  // e_217: "IO/1 Alarm",
  // e_218: "IO/2 Alarm",
  // e_219: "IO/3 Alarm",
  // e_220: "IO/4 Alarm",
  // e_221: "IO/5 Alarm",
  // e_222: "IO/6 Alarm",
  // e_223: "IO/7 Alarm",
  // e_224: "IO/8 Alarm",

  e_217: "Panic",
  e_218: "Front Door",
  e_219: "Middle Door",
  e_220: "Rear Door",
  e_221: "Right-Turn",
  e_222: "Left-Turn",
  e_223: "Brake",
  e_224: "Reverse",
  //IO1  Panic   IO8  Reverse   IO6  Turn Left  IO5  Turn Right   IO7 Brake​  IO2 Front Door  IO3 Middle Door IO4 Rear Door

  e_301: "FCW",
  e_302: "LDWS",
  e_303: "FCW",
  e_304: "PCW",
  e_305: "LDWS",
  e_306: "Road Overspd",
  e_307: "Obstacle Alarm",
  e_316: "Road Signs",
  e_317: "ADAS Camera",
  e_351: "Fatigue",
  e_352: "Call Up",
  e_353: "Smoking​",
  e_3541: "Distracted",
  e_3542: "No Driver",
  e_354: "Distracted",
  e_355: "No Driver",
  e_360: "Infrared Blok",
  e_361: "Camcover",
  e_366: "DMS Camera",
  e_367: "Change Driver",
  e_380: "Tire Pressure",
  e_401: "BSD Rear",
  e_402: "BSD Left",
  e_403: "BSD Right",
  e_308: "ADAS Error",
  e_356: "Seat Belt",
  e_357: "DMS Error",
  e_381: "​High TP",
  e_382: "Low TP",
  e_383: "High Tire Temp",
  e_384: "Sensor Abnormal",
  e_385: "Tyre Inbalance",
  e_386: "Leak Alarm",
  e_387: "Sensor Voltage Low",
  e_431: "HA",
  e_432: "HB",
  e_433: "HC",
  e_434: "Idiling",
  e_435: "Ignition Error",
  e_436: "Coasting Alarm​",
  e_437: "Engine Overload",

  e_624: "BSD Front",

  ntpfuwuqi: "NTP Server",
  ntpduankou: "NTP Port",

  gjd: "Track",
  item_addr: "Location",
  // zt_1: "Online",
  // zt_0: "Offline",
  // zt_2: "Alarm",
  // gps_0: "(LBS)",
  // gps_1: "(GPS)",
  // gps_2: "(No GPS)",

  zy: "Note：Only online device can send.",
  btnSend: "Send",
  table_title: "Send List",
  table_zd: "Device",
  table_sj: "Time",
  table_zt: "State",
  table_nr: "Content",
  empty_nr: "Send Content",
  fanyi1: "Are you sure of format HDD?​",
  fanyi2: "Are you sure of format SD?​",
  fanyi3: "Are you sure of adjusting G-sensor?​",
  fanyi4: "Ignition ON",
  fanyi5: "Ignition OFF",
  fanyi6: "(Time：Sec)",
  fanyi7: "Door",
  fanyi8: "OFF",
  fanyi9: "ON",
  fanyi10: "Power",
  fanyi11: "OFF",
  fanyi12: "ON",
  fanyi13: "Intercom",
  fanyi14: "Listen",
  fanyi15: "Mileage",
  fanyi16: "KM",
  fanyi17: "Speed",
  fanyi18: "Overspd Time",
  fanyi19: "Time:Sec",
  fanyi20: "Break Time",
  fanyi21: "Driving Time/Day",
  fanyi22: "Driving Time",
  fanyi23: "Parking Time​",
  fanyi24: "Basic Info​",
  fanyi25: "Factory No.",
  fanyi26: "Device Info",
  fanyi27: "Firmware​",
  fanyi28: "IMEI",
  fanyi29: "Network&Wifi",
  fanyi30: "Type",
  fanyi31: "Signal",
  fanyi32: "IP",
  fanyi33: "WIFI",
  fanyi34: "Signal",
  fanyi35: "Record",
  fanyi36: "Channel",
  fanyi37: "Record",
  fanyi38: "Camera",
  fanyi39: "Disk",
  fanyi40: "Disk",
  fanyi41: "Capacity",
  fanyi42: "Surplus",
  fanyi43: "Setup",
  fanyi44: "Acquire",
  fanyi45: "System",
  fanyi46: "Device",
  fanyi47: "Time",
  fanyi48: "Power",
  fanyi49: "Record",
  fanyi50: "Basic Setup",
  fanyi51: "Record",
  fanyi52: "Display",
  fanyi53: "Memory",
  fanyi54: "Network",
  fanyi55: "Cellular",
  fanyi56: "Server",
  fanyi57: "Sensor",
  fanyi58: "Speed",
  fanyi59: "IO",
  fanyi60: "Gsensor",
  fanyi61: "Temp",
  fanyi62: "Voltage",
  fanyi63: "Date Form",
  fanyi64: "Standby",
  fanyi65: "Time Type",
  fanyi66: "Time Zone",
  fanyi67: "Summer Time",
  fanyi68: "Start Time",
  fanyi69: "End Time",
  fanyi70: "Time Offset(Min)",
  fanyi71: "Power Type",
  fanyi72: "Delay Time",
  fanyi73: "Video Format​",
  fanyi74: "Camera Type",
  fanyi75: "Record mode",
  fanyi76: "Audio Type",
  fanyi77: "Pre-Record",
  fanyi78: "Delay-Record",
  fanyi79: "Record Lock",
  fanyi80: "Sec",
  fanyi81: "Day",
  fanyi82: "Channel",
  fanyi83: "Video Type",
  fanyi84: "Record Switch",
  fanyi85: "Res",
  fanyi86: "Frame",
  fanyi87: "Quality",
  fanyi88: "Audio",
  fanyi89: "Mirror",
  fanyi90: "Rotate",
  fanyi91: "Disk Type",
  fanyi92: "Record mode",
  fanyi93: "Cellular Type",
  fanyi94: "Mode",
  fanyi95: "Center No.",
  fanyi96: "User Name",
  fanyi97: "Password",
  fanyi98: "Server",
  fanyi99: "Switch",
  fanyi100: "Connection Type",
  fanyi101: "Server IP",
  fanyi102: "Port",
  fanyi103: "Domain",
  fanyi104: "Action Type",
  fanyi105: "Record​",
  fanyi106: "Trigger Out",
  fanyi107: "Alarm Threshold",
  fanyi108: "Anti-Shake Time",
  fanyi109: "Speed Source",
  fanyi110: "Speed Unit​",
  fanyi111: "IO Channel",
  fanyi112: "Trigger Mode",
  fanyi113: "Delay",
  fanyi114: "Preview",
  fanyi115: "Temp Unit",
  fanyi116: "Delay​",
  fanyi117: "Min",
  fanyi118: "Number",
  fanyi119: "Device ID",
  fanyi120: "Time Interval",

  channel_no: "Channel",
  file_pos: "File Location",
  wjdx: "File Size",
  bjsj: "Start Time",
  ejsj: "End Time",
  bjnr: "Content",
  wlmc: "Fence Name",
  til1: "Request",

  dName: "Device ID",
  fChannelNo: "Channel",
  fFileStartTime: "Start Date",
  fFileEndTime: "End Date",
  fState: "Uploading",
  til8: "Time",
  choose1: "Uploading",
  choose2: "Done",
  choose3: "Overdue",
  choose4: "Failure",
  choose5: "Not Uploaded",
  mMediaFileName: "File Name",
  num: "Freq",
  qingshuruchaxunguanjianzi: "Search Plate or ID.",
  ////////////////////////////////////////////////
  qingxuanze: "Please Select",
  gongxuanze: "Select ",
  liangche: "Vehicle",
  qingxuanzecheliang: "Select Vehicle​",
  zanwujieguo: "No Data.",
  qingshuruzhengquedekaishishijianhejieshushijian:
    "Please input correct startitme and endtime.",
  quanbu: "All",
  zaixian: "Online",
  lixian: "Offline",
  baojing: "Alarm",
  guanzhu: "Focus",
  huise: "Grey",
  lvse: "Green",
  shangxian: "Online",
  wubaojing: "No Alarm",
  zhengchang: "ON",
  yousudu: "Speed",
  hongse: "Red",
  youbaojing: "Alarm",
  zise: "Purple",
  meiyouxinhao: "No Signal",
  huangse: "Yellow",
  guanbi: "OFF",
  lanse: "Blue",
  suduwei: "Speed",
  wangluo: "Network",
  weixing: "GPS",
  dianhuo: "Ignition",
  yue: "Yue",
  wuxian: "Wireless",
  yingli: "Mile",
  xiaoshi: "Hour",
  gongli: "KM",
  dakai: "ON",
  danping: "One",
  siping: "Four",
  jiuping: "Nine",
  shiliuping: "Sixteen",
  kaiguan: "Switch",
  shipin: "Video",
  guiji: "Trip",
  huifang: "Replay",
  geshihuayingpan: "​Format HDD",
  geshihua: "Format SD",
  lixianshichang: "Offline Time",
  dingweishijian: "Online Time", 
  lixiancheliang: "Offline", 
  tians:"Day",
  fen: "Min",
  shi: "H",
  miao: "Sec",
  // ka: "Card",
  biaozhun: "G sensor",
  geshihualeixing: "Format Type",
  querenyaogeshihuayingpanma: "Are you sure of format HDD?​",
  querenyaozhongqima: "Are you sure of rebooting？",
  zhongduan: "Device",
  wangluodizhi: "IP",
  youjiancaidanbufen: "Right Click Menu",
  //jiazaijiaobenku: "Loading",
  yijibaojing: "Level 1 Alarm",
  erjibaojing: "Level 2 Alarm",
  guge: "Google",
  baidu: "Baidu",
  xingshi: "Travel",
  daisu: "Idling",
  tingche: "Park",
  weidingwei: "NO GPS",
  dian: "Point",
  juxingquyu: "Rectangle",
  xian: "Line",
  yuanxingquyu: "Round",
  sudu: "Speed",
  sdka: "SD卡",
  buluxiang: "No Record",
  licheng: "Mileage",
  zhuangtai: "State",
  miaoshu: "Content",
  yingpan: "HDD",
  xitongguanli: "System",
  xinxi: "Info",
  zhongduanshezhi: "Device",
  xitongshizhong: "Time",
  xialingshikaiguan: "Summer Time",
  dianyuanguanli: "Power",
  fujiashezhi: "Driving​ Setup",
  luxiangshezhi: "Record",
  jibenshezhi: "Basic Setup",
  zhumaliu: "Mainstream",
  zimaliu: "Substream",
  dingshiluxiang: "​Timed Record",
  cunchushezhi: "Memory",
  xinxidiejia: "OSD",
  shezhi: "Setup",
  wangluoshezhi: "Network",
  zhongxinshezhi: "Server",
  bendishezhi: "Local",
  bohaoshezhi: "Dial",
  shengjifuwuqi: "FTP",
  baojinghewaishe: "Sensor",
  sudubaojing: "Speed",
  jiasudu: "Gsensor",
  yidongzhence: "Motion Detection",
  dianyabaojing: "Voltage",
  chuankouguanli: "Serial",
  yuntaikongzhi: "PTZ",
  yindaochengxu: "Guide Program",
  neihechengxu: "Core Program​",
  wenjianxitong: "File System​",
  yingyongchengxu: "Application",
  danpianji: "MCU",
  yingjianbanben: "PCB",
  shebeihao: "Device ID",
  shoujihao: "Phone No.",
  chepaihao: "Plate No.",
  zhongxin: "Server",
  xieyi: "Protocol",
  zhongxinxieyi: "Server 1 Protocol​",
  zhongxinxieyi2: "Server 2 Protocol​",
  xiaoshixuanxiang: "Time Type",
  shoudong: "Manual",
  xiaozhun: "Calibration",
  gpsxiaozhun: "GPS",
  chaoshishijian: "​Overdue",
  shiqu: "Time Zone",
  shijian: "Time",
  fuwuqi: "Server",
  duankou: "Port",
  kaiqi: "ON",
  kaishishijian: "Start Time",
  yiyue: "Jan",
  eryue: "Feb",
  sanyue: "Mar",
  siyue: "Apr",
  wuyue: "May",
  liuyue: "Jun",
  qiyue: "Jul",
  bayue: "Aug",
  jiuyue: "Sept",
  shiyue: "Oct",
  shiyiyue: "Nov",
  shieryue: "Dec",
  diyizhou: "First week",
  dierzhou: "Second week",
  disanzhou: "Third week",
  disizhou: "Fourth week",
  diwuzhou: "Fifth week",
  zhouyi: "Mon",
  zhouer: "Tue",
  zhousan: "Wed",
  zhousi: "Thu",
  zhouwu: "Fri",
  zhouliu: "Sat",
  zhouri: "Sun",
  jieshushijian: "End Time",
  pianyishijian: "Time Offset(Min)",
  dianyuanmoshi: "Power Type",
  dingshi: "Timing",
  yanshiguanji: "Delay OFF",
  fen: "Min",
  xiumianshangbao: "Sleeping Data​",
  pingbaoyanshi: "Screen",
  kaijishijian: "Startup Time​",
  guanjishijian: "Shutdown Time​",
  luxiangyanshi: "​Record Delay",
  xihuoluxiang: "Ignition Off Rec",
  zidongweihu: "​Auto Reset",
  weihushijian: "Reset​ Time",
  lianxujiashichaoshishijian: "Duration",
  dangrileijijiashichaoshi: "Duration/Day",
  shipinzhishi: "Video Format​",
  luxiangmoshi: "Record mode",
  kaijiluxiang: "Ignition",
  baojingluxiang: "Alarm",
  yinpinleixing: "Audio Type",
  tongdao: "Channel",
  fenbianlv: "Res​",
  zhenglv: "Frame",
  huazhi: "Image",
  luxiang: "Rec",
  jingxiang: "Mirror",
  luyin: "Audio",
  zuoyoujingxiang: "Left/Right",
  shangxiajingxiang: "Up/Down",
  shangxia: "Up/Down",
  baojingyulu: "Alarm Pre Rec",
  miao: "Sec",
  shangxiazuoyoujingxiang: "Left/Right + Up/Down",
  baojingluxiangyanshi: "Alarm Rec Delay",
  baojingwenjianbaohu: "Alarm File Lock​",
  tian: "Day",
  baojingwenjianshangchuan: "File Upload​",
  cipan: "Disk",
  wu: "Non",
  zhuluxiang: "Main Record",
  jingxiangluxiang: "Mirror Record",
  beixuan: "Backup",
  wangluoleixing: "Type",
  jierudian: "Access Point",
  zhongxinhaoma: "Center No.",
  yonghuming: "User Name",
  mima: "Password",
  bianhao: "IO​",
  mingcheng: "Name",
  dianping: "Volt",
  yanshi: "Delay",
  fangdou: "Hold",
  baojingliandong: "Linkage",
  jinji: "Panic",
  qianmen: "Front Door",
  zhongmen: "Middle Door",
  houmen: "Rear Door",
  youzhuan: "Turn Right",
  zuozhuan: "Turn Left",
  shache: "Brake​",
  daoche: "Reverse",
  leixing: "Type",
  zuozhi: "Limit",
  chaoshitingche: "Idiling",
  disuyujing: "Lowspd Warning",
  disubaojing: "Lowspd Alarm",
  chaosuyujing: "Overspd Warning",
  chaosubaojing: "Overspd Alarm",
  sudulaiyuan: "Speed Source",
  cheliang: "Vehicle",
  hunhe: "Mix",
  sududanwei: "Speed Unit​",
  maichongxishu: "Pulse",
  jijiasu: "HA",
  jijiansu: "HB",
  jizhuanwan: "HC",
  pengzhuang: "Collision",
  cefan: "Rollover",
  sanzhou: "Three Axis",
  liuzhou: "Six Axis",
  daijimoshi: "Standby",
  putong: "Normal",
  zimaliuluxiang: "Substream",
  beifenfuwuqi: "Backup Server",
  yuming: "Domain",
  didianping: "Low Volt",
  gaodianping: "High Volt",
  diwen: "Low Temp​",
  gaowen: "High Temp​",
  sheshidu: "Celsius",
  huashidu: "Fahrenheit",
  didianya: "Low Volt",
  jianting: "Listen",
  laichufaguanbiyoujiancaidan: "Trigger to close right menu",
  diaoyongguanbicaidandefangfa: "Calls to close menu​",
  diaoyong: "Call",
  zhongdeshijian: "Happening",
  fangda: "Enlarge",
  suoxiao: "Lessen",
  huoqushujushangxian: "Obtain data to get online",
  qingkongditu: "Clear Map​",
  panduanyouche: "Vehicle Available​",
  chulizuobiancheliangshifouxuanzhong: "Vehicle Available",
  nan: "Male",
  guanzhuchenggong: "Focus Done​",
  quxiaoguanzhu: "Focus Removal​",
  xiafaguanbi: "Close",
  guanbiyoujiancaidan: "Close right-click menu",
  xianpanduansuoyoushipinshifouzhengzaibofang:
    "Check if all videos are playing",
  xiaohuishipinhefasongguanbishipin: "Destroy video and close video streaming",
  xiafabofangshipinmingling: "Issue order of video playing",
  shipinzhengzaibofang: "Video Playing",
  shipinmeibofang: "Video Not Played",
  genjurongqidekuangaohewanggebujudelieshujisuanmeigequyudekuanhegao:
    "Counting the width and height of each zone according to the container height and network layout",
  shezhimeigequyudekuanhegao: "Configure the width and height of each zone",
  shebeilixian: "Device Offline",
  minglingxiafachenggong: "Command Done​",
  fasongchenggong: "Send Done​",
  weiluxiang: "Not Rec​",
  diushi: "Loss",
  shujucunchuqilaifangbianyihoudiaoyong: "Data stored for future call",
  meiyouchulifangshi: "No handling method",
  caozuochenggong: "Handle Done​",
  wenbenxinxifasongchenggong: "TXT Sending Done​",
  yinpin: "Audio",
  buxuyaodiaoyongxiamiandefangfa: "No need to call below methods",
  shishijiankongyemianshengchengbaojingxinxi:
    "Realview page generate alarm info",
  canshushezhi: "Parameter Set​",
  canshushezhihuodeshuju: "Parameter config acquire data​",
  qingshezhizhengquedezuigaosudu: "Please input the max speed",
  xuyaozhuanwei: "To converted to",
  jinzhi: "Binary",
  qingshezhizhengquedechaosuchixushijian:
    "Please input correct the right overspeeding duration",
  qingshezhizhengquededangtianleijijiashishijian:
    "Please set up the daily accumulative driving time",
  qingshezhizhengquedelianxujiashishijian:
    "Please set up the correct driving time",
  qingshezhizhengquedezuichangtingcheshijian:
    "Please input the correct parking time",
  qingshezhizhengquedezuixiaoxiuxishijian: "Please fill in the Max break time",
  qingtianxieduiyingdeshuzhi: "Fill in right time",
  duijianggongneng: "Intercom",
  shangbaoshijian: "Upload Time",
  guolvkongge: "Filtering",
  bunnegxiaoyu0huozhedayu0: "No less than 0 or equal to 0​",
  liangxiangshujubunengquanbuweikong: "Two data inputs can not be vacant",
  zhinengdayu: "Only More Than​",
  bunengxiaoyu: "Not Less Than​",
  huozhedengyu: "Or Equal To​",
  qingshuruxiafaneirong: "Input content to be issued",
  ruo: "Weak",
  suoshugongsi: "Company",
  houtaixuanzhongzhijiezhanshidizhi: "Back server select and display address",
  jiuzhijiezhanshidizhidaima: "Show address code",
  dingwei: "Position",
  ditu: "Map",
  yingxiang: "Video Clip​",
  biaozhu: "Notes",
  chulitongdaoshuliang: "Channel No. Handled​",
  fasong: "Send",
  qingqiu: "Request",
  kaiqishubiaogunlunsuofang: "Mouse roll to enlarge and lessen",
  yangshikeyizijidingyi: "Format defineable",
  qbykycx: "Monthly only query",
  shipinzhedang: "Cam-Covered",
  user: "User",
  auth_1: "System Admin",
  auth_2: "Company Admin",
  auth_3: "Fleet Admin",
  auth_4: "Regular",
  e_: "",
  sfjxgk: "Do you want to keep it live ?",
  jx: "YES",
  gbts: "NO",
};
